import React from "react"
import styled from "styled-components"

export const ParagraphTextStyle = styled(
  ({ children, marginTop, marginBottom, fStyle, ...props }) => (
    <p {...props}>{children}</p>
  )
)`
  color: ${props => props.color};
  font-family: ${props => props.family};
  font-size: ${props => props.size};
  font-style: ${props => props.fStyle};
  font-weight: ${props => props.weight};
  letter-spacing: ${props => props.spacing};
  line-height: ${props => props.height};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  text-align: ${props => props.align};
  position: relative;
  z-index: 1;
`
const ParagraphText = ({
  children,
  className,
  color,
  family,
  fStyle,
  height,
  marginTop,
  marginBottom,
  size,
  spacing,
  weight,
  align,
}) => (
  <ParagraphTextStyle
    className={className}
    color={color}
    family={family}
    fStyle={fStyle}
    height={height}
    marginBottom={marginBottom}
    size={size}
    spacing={spacing}
    weight={weight}
    align={align}
    marginTop={marginTop}
  >
    {children}
  </ParagraphTextStyle>
)

export default ParagraphText
