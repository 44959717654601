import React from "react"
import PropTypes from "prop-types"
import { Navbar, Nav, Container } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import {
  TABLET_SCREEN_WIDTH,
  MOBILE_XS_SCREEN_WIDTH,
  WINDOW_GLOBAL,
  MOBILE_SCREEN_WIDTH,
  actualCountry,
} from "../../utils/constants"
import Resource from "./language.json"

export const LinkPage = styled(Nav.Link)`
  color: #343a40;
  font-family: ubuntu;
  ${props => props.footer && "font-family: cabin; color: #fff;"}
  font-size: ${props => (!props.size ? "14px;" : "16px;")};
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  &:hover {
    color: #26a071;
  }
  @media (max-width: 768px) {
    text-align: center;
    border: none;
  }
`
export const CallToAction = styled(Nav.Link)`
  background: rgba(0, 221, 148, 0.05);
  border-radius: 4px;
  border: 1px solid #00b77a;
  box-sizing: border-box;
  color: #26a071;
  font-family: ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1.5px;
  &:hover {
    color: #26a071;
  }
  @media (max-width: 1024px) {
    font-size: 10px;
  }
`
export const NavbarContainer = styled(Navbar)`
  box-shadow: 0px 4px 4px rgba(110, 126, 242, 0.15);
  padding-bottom: 0px;
`
const Header = () => {
  const component = Resource[process.env.GATSBY_SITE_VERSION]
  const data = useStaticQuery(graphql`
    query {
      ibanLogo: file(relativePath: { eq: "mx/iban-online-logo.png" }) {
        ...customFragmentImageFixed
      }
      ibanLogoXs: file(relativePath: { eq: "mx/iban-online-mobile-logo.png" }) {
        ...customFragmentImageFixed
      }
    }
  `)

  const hasShowRequestButton = () =>
    WINDOW_GLOBAL.innerWidth >= TABLET_SCREEN_WIDTH &&
    WINDOW_GLOBAL.location.pathname !== "/"

  const hasShowRequestButtonMobile = () =>
    WINDOW_GLOBAL.innerWidth <= MOBILE_SCREEN_WIDTH &&
    WINDOW_GLOBAL.location.pathname !== "/"

  return (
    <NavbarContainer expand="lg">
      <Container>
        <Navbar.Brand href="/">
          {WINDOW_GLOBAL.innerWidth < MOBILE_XS_SCREEN_WIDTH ? (
            <Img
              fixed={data.ibanLogoXs.childImageSharp.fixed}
              alt="Logo de la página para celular"
            />
          ) : (
            <Img
              fixed={data.ibanLogo.childImageSharp.fixed}
              alt="Logo de la página"
            />
          )}
        </Navbar.Brand>
        {hasShowRequestButtonMobile() && (
          <CallToAction href="/">SOLICITAR</CallToAction>
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
          <LinkPage href="/quienes-somos/">QUIÉNES SOMOS</LinkPage>
          {actualCountry === "mx" && (
            <LinkPage href="/agentes/">{component && component.agent}</LinkPage>
          )}
          <LinkPage href="/productos/">
            {component && component.creditToYourMeasure}
          </LinkPage>
          <LinkPage href="/refiere-y-gana/">
            {component && component.refer}
          </LinkPage>
          {/* <LinkPage href="/blog/">BLOG</LinkPage> */}
        </Navbar.Collapse>
        {hasShowRequestButton() && (
          <CallToAction href="/">
            {component && component.callToAction}
          </CallToAction>
        )}
      </Container>
    </NavbarContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
