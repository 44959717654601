import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`html{
    box-sizing: border-box;
}
   *, *:before, *:after {
    box-sizing: inherit;
  }

  .pt-2 .row{
    justify-content: space-evenly;
    padding: 0px 30px;
  }

  .pt-2 .row .card-deck {
    max-width: 255px
  }

  .offset-0 .size-icon {
    height: 56px;
    width: auto;
  }

  .size-icon {
    height: 75px;
    width: auto;
  }
`
