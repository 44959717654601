import React, { useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ToastContainer } from "react-toastify"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"
import styled from "styled-components"

import Header from "./Header"
import Footer from "./Footer"
import { GlobalStyle } from "../styles/global-style-component"
import { WINDOW_GLOBAL } from "../utils/constants"

export const MainStyle = styled.div`
  background-color: ${props => props.background};
`
const Layout = ({ children, background }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  useEffect(() => {
    const { search } = WINDOW_GLOBAL && window.location
    const params = new URLSearchParams(search)
    const gclidValue = params.get("gclid")
    if (gclidValue !== null || gclidValue !== undefined) {
      localStorage.setItem("gclid", gclidValue)
    }
  }, [])

  return (
    <Fragment>
      <Header siteTitle={data.site.siteMetadata.title} />
      <MainStyle background={background}>
        <main>{children}</main>
        <Footer />
      </MainStyle>
      <GlobalStyle />
      <ToastContainer />
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
