import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { actualCountry, WINDOW_GLOBAL } from "../utils/constants"

function SEO({ description, lang, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const canonicalOwn = WINDOW_GLOBAL && window.location.href
  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = keywords || [
    "Préstamo con garantía",
    "Reduce la hipoteca",
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        {
          rel: "canonical",
          href: canonicalOwn,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content:
            actualCountry === "mx"
              ? "google-site-verification=oVYhDWpehTyNz7QM5H6q23ObRprgb1cXuowMoB4s0Os"
              : actualCountry === "do"
              ? "google-site-verification=GZZ3yjOLrPRWijGm0RjbyfDM-1BTxwu7gvbtwekk9XM"
              : "",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        metaKeywords && metaKeywords.length > 0
          ? {
              name: `keywords`,
              content: metaKeywords.join(`, `),
            }
          : []
      )}
    />
  )
}

SEO.defaultProps = {
  description: ``,
  keywords: [],
  lang: `en`,
  meta: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
