import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import { Icon, LinkContainer, FooterParent } from "./styles"
import { LinkPage } from "../Header"
import { WINDOW_GLOBAL, actualCountry } from "../../utils/constants"
import iconFacebook from "../../assets/icons/facebook.svg"
import iconInstagram from "../../assets/icons/instagram.svg"
import iconLocation from "../../assets/icons/location.svg"
import iconWhatsApp from "../../assets/icons/whatsapp.svg"
import LogoHorizontal from "../../assets/images/mx/logo-horizontal-blanco.png"
import Resource from "./language.json"
import ParagraphText from "../ParagraphText"
import { navigate } from "gatsby"

const scrollTop = () => WINDOW_GLOBAL.scrollTo({ top: 0, behavior: "smooth" })
const showRequest = WINDOW_GLOBAL && window.location.pathname === "/"
const component = Resource[process.env.GATSBY_SITE_VERSION]

const Footer = () => {
  return (
    <FooterParent className="bg-dark">
      <Container>
        <Row className="pt-5">
          <Col sm={12} md={12} lg={3} className="separator padding-top">
            <LinkContainer>
              {showRequest && (
                <LinkPage footer="true" role="button" onClick={scrollTop}>
                  {component && component.applyForLoan}
                </LinkPage>
              )}
              <LinkPage footer="true" href="/quienes-somos/">
                {component && component.aboutUs}
              </LinkPage>
              {actualCountry === "mx" && (
                <LinkPage footer="true" href="/agentes/">
                  {component && component.agent}
                </LinkPage>
              )}
              <LinkPage footer="true" href="/productos/">
                {component && component.customizedCredit}
              </LinkPage>
              <LinkPage footer="true" href="/refiere-y-gana/">
                {component && component.refers}
              </LinkPage>
              {/* <LinkPage footer="true" href="/blog/">
                Blog
              </LinkPage> */}
            </LinkContainer>
          </Col>
          <Col sm={12} md={12} lg={3} className="align-self-start padding-top">
            <LinkContainer>
              <LinkPage className="none" footer="true" href="">
                Productos:
              </LinkPage>
              <LinkPage footer="true" href="/credito-solucion/">
                {component && component.products.solution}
              </LinkPage>
              <LinkPage footer="true" href="/credito-negocio/">
                {component && component.products.pyme}
              </LinkPage>
              <LinkPage footer="true" href="/credito-construccion/">
                {component && component.products.construction}
              </LinkPage>
              <LinkPage footer="true" href="/credito-mejora-tu-cuota/">
                {component && component.products.ImproveYourMortgage}
              </LinkPage>
            </LinkContainer>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={3}
            className="order-first order-lg-last offset-0 offset-lg-3"
          >
            <div className="d-flex align-items-center ptSecond justify-content-center">
              <img
                src={LogoHorizontal}
                alt="Logo Horizontal Blanco"
                className="logo-horizontal"
                aria-hidden="true"
                onClick={() => navigate("/")}
              />

              <Icon
                src={iconWhatsApp}
                alt="Red social whatsApp"
                role="button"
                onClick={() =>
                  WINDOW_GLOBAL && window.open(component && component.whatsapp)
                }
              />
              <Icon
                src={iconInstagram}
                alt="Red social instagram"
                role="button"
                onClick={() =>
                  WINDOW_GLOBAL && window.open(component && component.instagram)
                }
              />
              <Icon
                src={iconFacebook}
                alt="Red social facebook"
                role="button"
                onClick={() =>
                  WINDOW_GLOBAL && window.open(component && component.facebook)
                }
              />
            </div>
          </Col>
        </Row>
        <Row className="text-light pt-5">
          <ParagraphText
            family="cabin"
            size="16px"
            className="mr-auto ml-auto d-block d-lg-flex align-items-center"
          >
            <img src={iconLocation} alt="Localización de nuestra empresa" />
            {component && component.address}
          </ParagraphText>
        </Row>
        {actualCountry === "mx" ? (
          <Row className="text-light">
            <ParagraphText
              family="cabin"
              size="16px"
              className="mr-auto ml-auto d-block d-lg-flex align-items-center"
            >
              Servicio Postventa{" "}
              <LinkPage
                footer="true"
                size="true"
                href="mailto:asistencia@ibanonline.com"
              >
                asistencia@ibanonline.com
              </LinkPage>{" "}
              |
              <LinkPage footer="true" size="true" href="tel:56-1412-8181">
                +55 4445 9254
              </LinkPage>
            </ParagraphText>
          </Row>
        ) : null}
        <Row className="text-light">
          <ParagraphText
            family="cabin"
            size="16px"
            className="mr-auto ml-auto d-block d-lg-flex align-items-center"
          >
            <LinkPage footer="true" size="true" href="/terminos-y-condiciones/">
              Política privacidad
            </LinkPage>{" "}
            {actualCountry === "do" && (
              <>
                |
                <LinkPage
                  footer="true"
                  size="true"
                  href="/politica-privacidad/"
                >
                  Aviso de privacidad
                </LinkPage>{" "}
              </>
            )}
            |
            <LinkPage footer="true" size="true" href="/politica-cookies/">
              {actualCountry === "do"
                ? "Política de cookies"
                : "Términos y condiciones"}
            </LinkPage>
          </ParagraphText>
        </Row>
      </Container>
    </FooterParent>
  )
}

export default Footer
