import styled from "styled-components"

export const FooterParent = styled.footer`
  @media (max-width: 375px) {
    padding-top: 3rem;
  }
  .logo-horizontal {
    width: 130px;
    margin-right: 2rem;
    cursor: pointer;
    @media (max-width: 320px) {
      margin-right: 0rem;
    }
  }
  .padding-top {
    @media (max-width: 768px) {
      padding-top: 3rem;
    }
  }

  .separator {
    &:after {
      background-color: #e1e1e1;
      content: "";
      height: 58.5%;
      position: absolute;
      right: 25%;
      top: 6px;
      width: 2px;
    }
    @media (max-width: 1024px) {
      &:after {
        right: 18%;
      }
    }
    @media (max-width: 768px) {
      &:after {
        content: none;
      }
    }
  }
  .none {
    cursor: default;
    &:hover {
      color: #fff;
    }
  }
  @media (max-width: 768px) {
    text-align: center;
  }
  @media (min-width: 1024px) {
    .ptSecond {
      padding-top: 38px;
    }
  }
`
export const Icon = styled.img`
  height: 37px;
  @media (min-width: 1440px) {
    padding-left: 1rem;
  }
  @media (max-width: 1024px) {
    padding-left: 1rem;
  }
`
export const LinkContainer = styled.div`
  display: inline-block;
`
